<template>
    <div class="card opd-card">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Photo</th>
                        <th>Consultant</th>
                        <th>Serial no</th>
                        <th>Patient Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in bills" :key="i">
                        <td class="image-container"> <img :src="item.consultant_photo" alt="avatar" class="avatar-image"></td>
                        <td>
                            <p>{{ item.service_resource && item.service_resource.name }}</p>
                        </td>
                        <td>{{ item.serial_no }}</td>
                        <td> 
                            <div class="col">
                                <strong>
                                    <h3 class="capitalize">{{ item.contact_profile ? item.contact_profile.full_name : ''}} (Age: {{ calculateAge(item.contact_profile.birthday) }}, Sex: {{ item.contact_profile.gender }}) </h3>
                                </strong>
                                {{ item.contact_profile ? item.contact_profile.mobile_no : '' }} | {{ item.contact_profile ? item.contact_profile.serial_no : '' }}
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>

            <p v-if="!bills.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from "vue";
    import figureFormatter from "@/services/utils/figureFormatter";
    import {useRoute, useRouter} from "vue-router";
    import handleHospital from "@/services/modules/hospital";
    import handleHospitalPHC from "@/services/modules/hospital/phc";
    import handleCompany from "@/services/modules/company";
    import phcPdfHelper from "@/services/utils/pdf/phcPdfHelper";
    import { dateOfBirthConvertToAge } from '@/services/utils/global'

    const props = defineProps({
        bills: Array,
        isDoctor: {
            type: Boolean,
            default: false
        }
    })

    const $route = useRoute();
    const $router = useRouter();

    const {commaFormat} = figureFormatter();
    const $emit = defineEmits(['onEdit']);
    const { fetchSinglePHC } = handleHospitalPHC();
    const { fetchCompanyInfo } = handleCompany();
    const { generatePdf } = phcPdfHelper();
    const singlePhc = ref({});
    const companyInfo = ref({});

    const calculateAge = (birthday) => {
        const { year } = dateOfBirthConvertToAge(birthday)
        return `${year}y` 
    }

    const conditionalRedirect = (item) => {
        props.isDoctor? goToPrescription(item) : goToPhc(item.id, item.contact_profile.serial_no);
    }

    const goToPrescription = (item) => {
        const params = $route.params;
        params.serviceId = item.id;
        $router.push({name: 'add-prescription',
            params: params,
            query: $route.query
        })
    }

    const goToPrint = (id, patientId) => {
        const singlePhcQuery = `/${id}?company_id=${$route.params.companyId}`;

        Promise.all([
            fetchCompanyInfo($route.params.companyId).then((res) => {
                if(res.status) {
                    companyInfo.value = res.data;
                }
            }),
            fetchSinglePHC(singlePhcQuery).then((res) => {
                if(res.status){
                    singlePhc.value = res.data
                }
            })
        ]).catch(e => console.log('Something went wrong'))
        .finally(() => generatePdf(companyInfo.value, singlePhc.value, patientId))
    }

    const goToPhc = (id, patientId) => {
        $router.push({
            name: 'phc-update',
            params: {
                companyId: $route.params.companyId,
                moduleId: $route.params.moduleId,
                menuId: $route.params.menuId,
                pageId: $route.params.pageId
            },
            query: {
                id: id,
                start: $route.query.start,
                end: $route.query.end,
                page: $route.query.page,
                patientId: patientId
            }
        })
    }
</script>

<style scoped>
.opd-card {
    min-height: 200px !important;
}
.btn-custom{
    border: 1px solid;
    background-color: #fff !important;
    color: #000 !important;
    border-color: #7367f0;
    font-weight: 600;
    border-radius: 20px;
}
.btn-custom:hover{
    color: #fff !important;
    background-color: #7367f0 !important;
    box-shadow: 0 8px 25px -8px #7367f0 !important;
}
.image-container{
    width: 20px;
}
.avatar-image{
    width: 65px;
}
</style>