<template>
  <div class="row">
    <div class="col-md-7">
      <p class="h1 m-0">{{ title }}</p>
    </div>
    <div class="col-md-5">
      <div class="float-end">
        <a style="margin-right: 25px;" v-if="showSettingBtn" href="#" @click="$emit('onClickSettingButton')" class="mr">Setting</a>
        <slot name="title-details"></slot>

        <span class="p-2" v-if="showExtraField">{{ extraFieldData }}</span>

        <button
          style="min-width: 64px;margin-right: 25px;"
          v-if="showExtraBtn"
          @click="$emit('onClickExtraBtn')"
          class="btn btn-light waves-effect waves-float waves-light"
        >
          {{ extraBtnTitle }}
        </button>
        
        <button
          style="min-width: 64px;margin-right: 25px;"
          v-if="showAddNew"
          @click="$emit('onClickAddNewButton')"
          class="btn btn-primary waves-effect waves-float waves-light"
        >
            {{ btnTitle }}
        </button>

        <button
          v-if="showBtn"
          @click="$emit('onClickCloseButton')"
          class="btn btn-close"
        >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleButton',
  // props:['title', 'btnTitle']
  props: {
    title         : String,
    btnTitle      : String,
    extraBtnTitle      : String,
    extraFieldData: String,
    locations: Array,
    showBtn       : {
      default: true
    },
    showAddNew    : {
      default: false
    },
    showExtraBtn    : {
      default: false
    },
    showSettingBtn: {
      default: false
    },
    showExtraField: {
      default: false
    }
  }
}
</script>

<style scoped>
.cancel-btn {
  background-color: #F0F2F5;
  color: #7D7D7D;
}

.mr{
  margin-right: 1rem;
}

.cancel-btn:hover {
  background-color: #e9edf5;
}

hr {
  margin: 0 -3%;
  width: 106%;
}

@media screen and (min-width: 1200px) {
  .btn-close {
    /* margin-left: 200px; */
    /* font-size: 32px; */
    /* margin-top: 5px; */
    width: 30px;
    height: 30px;
    background-color: #E4E6EB;
    border-radius: 50%;
    /* margin-right: 30px; */
  }

  .btn-close:hover {
    width: 30px;
    height: 30px;
    background-color: #D8DADF;
    border-radius: 50%;
  }
}

@media screen and (max-width: 720px) {
  .btn-close {
    width: 25px;
    height: 25px;
    background-color: #E4E6EB;
    border-radius: 50%;
    margin-top: -5px;
    margin-right: -5px;
  }
}
</style>
