<template>
    <div class="card">
        <div class="bg-blue-light p-2">

            <div class="row" v-if="isOpdAppointment">
              <div class="col-md-12">
                <p class="h1 m-0">{{ title }}</p>
              </div>
            </div>

            <TitleButton
                v-else
                :showBtn="false"
                :showAddNew="false"
                :showExtraBtn="false"
                btnTitle="New Prescription"
                extraBtnTitle="Patient Register"
                :title="title"
            />
        </div>
        
        <div v-if="!isOpdAppointment" class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="getOPDBillings"
            >

              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                 <label for="colFormLabel" class="col-form-label">Registration Status: </label>
                  <v-select
                      v-model="completionStatus" class="w-100" 
                      placeholder="Registration Status"
                      :options="statuses" label="name"
                      :reduce="(name) => name.id"
                  />
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-if="completionStatus === 'completed'">
                <label for="colFormLabel" class="col-form-label">Template Status: </label>
                  <v-select
                      v-model="templateStatus" class="w-100" 
                      placeholder="Registration Status"
                      :options="templateStatuses" label="name"
                      :reduce="(name) => name.id"
                  />
              </div>

              <div class="col-12 col-sm-6 col-md-5 col-lg-4">
                  <label for="colFormLabel" class="col-form-label">Patient</label>
                  <AsyncSelect
                    placeholder="Patient Name, ID, Mobile No"
                    v-model="patient"
                    :api-service="fetchContactProfiles"
                    :additional-query="additionalQuery"
                    :format-label="formatPatientLabel"
                  />
              </div>

              <div class="col-12 col-sm-5 col-md-5 col-lg-2">
                <label for="colFormLabel" class="col-form-label">Patient ID</label>
                <vField
                  v-model="serialNo"
                  name="serial"
                  type="number"
                  class="form-control"
                  placeholder="Patient ID"
                  @keyup="getOPDBillings"
                />
              </div>

            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2">

            <OpdBillingTable
              v-if="isOpdAppointment" 
              :bills="bills" 
              :isDoctor="true"
            />
            
            <ListTable 
              v-else
              :bills="bills" 
              :isDoctor="true"
            />

            <div class="px-2 bottom-0 mt-2">
                <Pagination
                    :offset="offset"
                    @onPageChange="onPageChange"
                    ref="pagination"
                />
            </div>
        </div>

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButtonHospital.vue';
    import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
    import ListTable from '@/components/molecule/company/hospital/OpdPrescriptionBillingListTable.vue';
    import OpdBillingTable from '@/components/molecule/company/hospital/OpdBillingTable.vue';
    import {useRoute, useRouter} from "vue-router";
    import handleHospitalBilling from "@/services/modules/hospital/billing";
    import {computed, inject, onMounted, ref} from "vue";
    import Loader from "@/components/atom/LoaderComponent.vue";
    import Pagination from "@/components/atom/Pagination.vue";
    import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
    import handleContact from "@/services/modules/contact";
    import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
    import {useStore} from "vuex";

    const $route = useRoute();
    const $router = useRouter();
    const $store = useStore();

    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const {fetchOPDBillings} = handleHospitalBilling();
    const {fetchContactProfiles} = handleContact()
    const {formatPatientLabel} = useAsyncDropdownHelper()

    const offset = ref(10);
    const loading = ref(false);
    const bills = ref([])
    const patient = ref(null)
    const serialNo = ref(null)
    const keyword = ref('')
    const doctor_id = ref(null)
    const completionStatus = ref('pending');
    const templateStatus = ref('');
    const statuses = ref([
      {id: 'pending', name: 'Pending'},
      {id: 'completed', name: 'Completed'}
    ]);
    const templateStatuses = ref([
      {id: 'active', name: 'Active'},
      {id: 'inactive', name: 'Inactive'}
    ]);
    const additionalQuery = {
      type: "patient"
    }

    const isOpdAppointment = computed(() => {
       return $route.name === 'opd-appointment-list';
    })

    const title = computed(() => {
      let opdTitle = 'OPD Prescription';
       
      if(isOpdAppointment.value){
        opdTitle = `Appointment Queue Management (${start.value})`;
      }

      return opdTitle;
    })

    const start = computed(() => $route.query.start);
    const end = computed(() => $route.query.end);
    const page = computed(() => $route.query.page);
    const companyId = computed(() => $route.params.companyId);
    
    onMounted(() => {
        getOPDBillings()
    })

    const setPaginationData = (data) => {
        $store.commit('setPaginationData', data)
    };

    const onPageChange = async (page) => {
        let routeQuery = Object.assign({}, $route.query)
        routeQuery.page = page
        $router.push({path: $route.path, query: routeQuery})
        .then(() => {
          getOPDBillings()
        })
    }

    const setPagination = (data) => {
        setPaginationData({
          records: data.total,
          perPage: data.per_page,
          currentPage: data.current_page,
        })
    }

    const getQuery = () => {
        let query = '?company_id=' + companyId.value
        if (!start && !end) return query
        if (start) query += '&start_date=' + start.value
        if (end) query += '&end_date=' + end.value
        query += '&offset=' + offset.value
        if (page) query += '&page=' + page.value
        if (keyword.value) query += '&q=' + keyword.value
        if (doctor_id.value) query += '&doctor_id=' + doctor_id.value
        if (completionStatus.value) query += '&completion_status=' + completionStatus.value
        if (templateStatus.value) query += '&template_status=' + templateStatus.value;
        if (serialNo.value) query += '&serial_no=' + serialNo.value
        if (patient.value) query += '&patient_id=' + patient.value.id
        if (isOpdAppointment.value) query += '&is_appointment=1'
        query += '&type=doctors_prescription'
        return query
    };

    async function getOPDBillings() {
        loading.value = true;
        try {
          const res = await fetchOPDBillings(getQuery());
          if (!res.status) {
            bills.value = []
            return $store.commit("resetPagination")
          }
          bills.value = res.data.data;
          if (bills.value.length > 0) {
            setPagination(res.data)
          }
        } catch (err) {
          if (err.response) {
            showError(err.response.message)
          }
        } finally {
          loading.value = false;
        }
    }
</script>

<style scoped>
@media (max-width: 768px) {
    .py-md-1{
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
}
</style>